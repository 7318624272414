import { getHeaders } from '../util/requests';

window.loadWeightProgress = () => {
  fetch('/progress/render_frame')
    .then(res => {
      return res.text();
    })
    .then(data => {
      $('#weight-progress').html(data);
      window.setProgressLinks();
    });
}

window.setProgressLinks = () => {
  $('.delete-progress').click((ev) => {
    const id = $(ev.currentTarget).attr('id');

    window.showConfirmModal(
      'Are you sure?',
      'Clicking the button below will delete this progress update.',
      'Delete It',
      ev => {
        fetch(`/progress/${id}`,
          {
            headers: getHeaders(),
            method: 'DELETE',
          })
          .then(res => {
            return res.text();
          })
          .then(data => {
            $(`.hp-${id}`).fadeOut();
            window.loadWeightProgress();
            window.hideModal();
          });
      },
    );

    ev.preventDefault();
  });

  $('#save-progress-form').submit((ev) => {
    fetch('/progress',
      {
        headers: getHeaders(),
        method: 'POST',
        body: JSON.stringify({
          health_profile: {
            current_weight: $('#hp-weight').val(),
            notes: $('#hp-notes').val(),
          }
        }),
      })
      .then(res => {
        return res.text();
      })
      .then(data => {
        window.loadWeightProgress();
      });

    ev.preventDefault();
  });
}
