/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails functionality
// window.Rails = require("@rails/ujs")
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
// require("trix")
// require("@rails/actiontext")
require("jquery")

// Tailwind CSS
import "stylesheets/application"

// Stimulus controllers
import "controllers"

// Jumpstart Pro & other Functionality
// import "src/actiontext"
import "src/confirm"
// import "src/direct_uploads"
import "src/forms"
// import "src/timezone"
// import "src/tooltips"
// import "src/effects"
import "src/panels"
import "src/modals"
import "src/meal_plans"
import "src/progress"

var ProgressBar = require('progressbar.js');

// import LocalTime from "local-time"
// LocalTime.start()

// ADD YOUR JAVACSRIPT HERE
// window.LogRocket = require('logrocket');

// Start Rails UJS
// Rails.start()

// Support component names relative to this directory:
// const componentRequireContext = require.context("components", true);
// const ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);

window.updatePrintModal = (url) => {
  window.modalSetContent(
    '<div class="p-4 text-center flash"><h3 class="mb-4">Your Plan Is Ready</h3>' +
    '<p class="text-lg">Your custom meal plan is ready to download. Please click the following button:</p>' +
    '<div class="mt-5 sm:mt-6">' +
    '<span class="flex w-full rounded-md shadow-sm">' +
    '<a href="' + url + '" type="button" id="pdf-download-button" class="font-semibold text-xl inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 hover:text-white">Download Plan PDF</a>' +
    '</span></div>' +
    '</div>' + 
    '<script type="text/javascript">' +
    '$("#pdf-download-button").click(function(ev) {' +
    'analytics.track("Meal Plan Download")' +
    '})'
  );
}

window.checkPrintStatus = (target) => {
  setTimeout(() => {
    $.getJSON(`${target}/check_file_status`)
      .done(function (json) {
        console.log(json);
        if (json.status === 'processing') {
          console.log('Still processing...');
          window.checkPrintStatus(target);
        } else if (json.status === 'ready') {
          console.log('Finished!', json.pdf_url);
          window.updatePrintModal(json.pdf_url);
        }
      })
      .fail(function (jqxhr, textStatus, error) {
        window.showErrorModal();
        console.log("Request Failed: " + error);
      });
  }, 1500);
}

window.setPrintLinks = () => {
  $('.print-link').click(function (ev) {
    const target = $(ev.currentTarget).data('url');

    $('#modal').addClass('loading-pdf');

    window.modalSetContent(
      '<div class="p-4 text-center"><h3 class="mb-4">Just a sec, we\'re getting it ready!</h3>' +
      '<p class="text-lg">We are formatting your meal plan and making it printer perfect. This process can take about 20-30 seconds.</p>' +
      '<div id="progress-bar" class="mx-10 my-5"></div>' +
      '</div>'
    );

    $.getJSON(`${target}/create_pdf`)
      .done(function (json) {
        console.log(json);
        if (json.status !== 'ok') {
          window.showErrorModal();
        }
      })
      .fail(function (jqxhr, textStatus, error) {
        window.showErrorModal();
        console.log("Request Failed: " + error);
      });

    var bar = new ProgressBar.Line('#progress-bar', {
      strokeWidth: 6,
      easing: 'easeInOut',
      duration: 30000,
      color: '#ED6A5A',
      trailColor: '#eee',
      trailWidth: 4,
      svgStyle: { width: '100%', height: '100%' }
    });
    window.showModal();
    bar.animate(1);

    // Start polling
    window.checkPrintStatus(target);

    // once finished:
    // $('#modal).removeClass('loading-pdf);

    // failesafe:
    setTimeout(() => {
      if ($('#modal').hasClass('loading-pdf')) {
        window.showErrorModal();
      }
    }, 80000);

    ev.preventDefault();
  });
}

window.showMealFilters = () => {
  $('#filter-meal-plan').click(function(ev) {
    const target = $(ev.currentTarget).data('url');
    window.modalStartLoading();
    $.getScript(target);
    ev.preventDefault();
  });
}

$(document).ready(() => {
  $('#open-sidebar').click(ev => {
    $('#sidebar-container').removeClass('hidden');
    setTimeout(() => {
      $('#sidebar-overlay').removeClass('opacity-0');
      $('#sidebar-menu').removeClass('-translate-x-full');
    }, 0);
    ev.preventDefault();
  });

  $('#close-sidebar, #sidebar-overlay').click(ev => {
    $('#sidebar-overlay').addClass('opacity-0');
    $('#sidebar-menu').addClass('-translate-x-full');
    setTimeout(() => {
      $('#sidebar-container').addClass('hidden');
    }, 100);
    ev.preventDefault();
  });

  $('#profile-nav').click(ev => {
    $('.profile-menu').toggleClass('profile-menu--active');
  });

  $(".panel-link").click(ev => {
    showPanel('test', 'this is a test');

    ev.preventDefault();
  });

  $('.hide-panel').click(ev => {
    window.hidePanel();
  });

  $('.close-modal').click(() => {
    window.hideModal();
  });
});
