const loadRecipe = ev => {
  ev.preventDefault();

  const recipeId = $(ev.currentTarget).data("recipe-id");
  const mealId = $(ev.currentTarget).data("meal-id");
  const title = $(ev.currentTarget).data("title");

  window.panelStartLoading();

  $.ajax({
    method: "GET",
    url: `/recipes/${recipeId}/modal`,
    success: html => {
      window.panelSetContent(title, html);
      $('#panel-content .swap-recipe-link').attr('data-meal-id', mealId).click(loadSwapRecipe);
      window.panelStopLoading();
    }
  });
}

const replaceRecipe = ev => {
  const mealId = $(ev.currentTarget).data("meal-id");
  const recipeId = $(ev.currentTarget).data("recipe-id");

  window.panelStartLoading();

  $.ajax({
    method: "GET",
    url: `/meals/${mealId}/replace/${recipeId}`,
    success: (html) => {
      $(`*[data-meal-id="${mealId}"]`).replaceWith(html)
      $(`*[data-meal-id="${mealId}"] .recipe-link`).click(loadRecipe);
      $(`*[data-meal-id="${mealId}"] .swap-recipe-link`).click(loadSwapRecipe);
      window.hidePanel();
    }
  });

  ev.preventDefault();
}

const loadSwapRecipe = ev => {
  ev.preventDefault();

  const mealId = $(ev.currentTarget).data("meal-id");
  const title = 'Select an Alternative Recipe';

  window.panelStartLoading();
  $.ajax({
    method: "GET",
    url: `/meals/${mealId}/get_alternatives`,
    success: (html) => {
      window.panelSetContent(title, html);
      window.panelStopLoading();
      $('.replace-recipe-link').click(replaceRecipe)
    }
  });
}

window.setMealPlanControls = () => {
  function onModalClick(event) {
    const id = event.currentTarget.id;
    const mealId = event.currentTarget.getAttribute('data-meal-id');
    const type = event.currentTarget.classList.contains('replace-recipe') ? 'replace-recipe' : 'view-recipe';

    if (type === 'replace-recipe') {
      loadReplaceRecipe(id);
    } else {
      loadRecipe(id, mealId);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  const overlay = document.querySelector('.modal-overlay');
  overlay.addEventListener('click', () => {
    closeModal();
  });

  const closemodal = document.querySelectorAll('.modal-close');
  for (let i = 0; i < closemodal.length; i++) {
    closemodal[i].addEventListener('click', () => {
      closeModal();
    });
  }

  window.replaceRecipe = (mealId, recipeId) => {
    console.log('Meal:', mealId);
    console.log('Recipe:', recipeId);
    $.ajax({
      method: "GET",
      url: `/meals/${mealId}/replace/${recipeId}`,
      success: (html) => {
        $(`*[data-meal-id="${mealId}"]`).replaceWith(html)
        $(`*[data-meal-id="${mealId}"]`).click(onModalClick);
        $(`*[data-meal-id="${mealId}"] .modal-open`).click(onModalClick);
        closeModal();
      }
    });

  }

  document.onkeydown = evt => {
    evt = evt || window.event
    let isEscape = false;

    if ("key" in evt) {
      isEscape = (evt.key === "Escape" || evt.key === "Esc")
    } else {
      isEscape = (evt.keyCode === 27)
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
      closeModal()
    }
  };

  const openModal = () => {
    $('.modal').removeClass('opacity-0')
    $('.modal').removeClass('pointer-events-none')
    $('body').addClass('modal-active')
  }

  const closeModal = () => {
    $('.modal').addClass('opacity-0')
    $('.modal').addClass('pointer-events-none')
    $('body').removeClass('modal-active')
    $('.modal-content-wrap').html('');
  }

  const todaysDate = new Date().getDay();
  const selectDate = (weekIndex, dateIndex) => {
    $(`.day-container`).removeClass('selected');
    $(`.week-container[data-week-id=${weekIndex}] .day-container[data-day-id=${dateIndex}]`).addClass('selected');
    // $('.day').hide();
    // $(`.day-${dateIndex}`).fadeIn();
    // $('.print-link').attr('href', `${$('.print-link').data('url')}/print.pdf`)
  }

  $('.day-nav-item').click(event => {
    const targetWeek = $(event.currentTarget).data('week-id');
    const targetDay = $(event.currentTarget).data("day-id");
    selectDate(targetWeek, targetDay);
  });

  const selectWeek = weekIndex => {
    $('.week-container').removeClass('selected');
    $(`.week-container[data-week-id=${weekIndex}]`).addClass('selected');

    $('.current-week').hide().html(weekIndex).fadeIn();
    $('#week-name').removeClass('flash');
    setTimeout(() => {
      $('#week-name').addClass('flash');
    }, 10);

    $('.grocery-list-link').attr('href', `${$('.grocery-list-link').data('url')}/${weekIndex}/groceries`)
    selectDate(weekIndex, 1);
  }

  const params = new URLSearchParams(window.location.search);
  const curWeek = params.has('week') ? parseInt(params.get('week')) : 1;

  selectWeek(1);
  // selectDate(curWeek, todaysDate);

  const setWeekNav = () => {
    const numWeeks = parseInt($('#week-name').data('total-weeks'));

    $('.next-week').click(event => {
      const currentWeek = parseInt($('.current-week').html());
      selectWeek(currentWeek < numWeeks ? currentWeek + 1 : 1);
    });

    $('.previous-week').click(event => {
      const currentWeek = parseInt($('.current-week').html());
      selectWeek(currentWeek === 1 ? numWeeks : currentWeek - 1);
    });
  }

  setWeekNav();

  $('.new-meal-plan-link').click(ev => {
    const isLegacyUser = ev.currentTarget.getAttribute('data-is-legacy-user');

    if (isLegacyUser === 'false') {
      return window.location = "/meal-plans/new";
    }

    const monthsRemaining = ev.currentTarget.getAttribute('data-month-credits');
    let creditsText;

    if (monthsRemaining === 1) {
      creditsText = 'You have credit for one additional meal plan.<br /> You may create a new one using the link below, however doing so will reset the existing one.'
    } else {
      creditsText = `You have credits for an additional ${monthsRemaining} months.<br /> Creating a new meal will use one of these credits and reset the existing one.`
    }

    window.modalSetContent(
      '<div class="p-4 text-center"><h3 class="mb-4">Request New Meal Plan</h3>' +
      '<p class="text-lg">' + creditsText + '</p>' +
      '<div class="mt-5 sm:mt-6">' +
      '<span class="flex w-full rounded-md shadow-sm">' +
      '<a href="/meal-plans/new" type="button" class="text-xl inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 leading-6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:leading-5 hover:text-white mx-auto">New Meal Plan &rarr;</a>' +
      '</span></div>' +
      '</div>'
    );
    window.showModal();
    ev.preventDefault();
  })
}

window.setRecipeAndSwapLinks = () => {
  $('.recipe-link').click(loadRecipe);
  $('.swap-recipe-link').click(loadSwapRecipe);
}

window.loadMealPlan = uuid => {
  $.get(`/meal-plans/${uuid}/render`)
    .done(data => {
      $('#loading').hide();
      $('#meal-plan-cont').html(data);
      window.setRecipeAndSwapLinks();
      window.setMealPlanControls();
    })
    .fail(() => {
      $('#loading').html('There was a problem loading the meal plan...');
    });
}
