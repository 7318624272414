window.setUploadForm = () => {
  const Forms = {
    init() {
      this.fileInputToggle();
    },

    fileInputToggle() {
      const inputs = document.querySelectorAll('.form-file');

      inputs.forEach(function (input) {
        const label = input.nextElementSibling,
          labelVal = label.innerHTML;

        input.addEventListener('change', function (e) {
          var fileName = '';

          if (this.files && this.files.length > 1) {
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
          } else {
            fileName = e.target.value.split('\\').pop();
          }

          if (fileName) {
            label.querySelector('span').innerHTML = fileName;
          } else {
            label.innerHTML = labelVal;
          }
        });
      });
    }
  };

  Forms.init();
}

window.setMPForm = () => {
  let unitsId, weightId, currentWeightId;
  const form = document.querySelector('.hp-form');

  const setRestrictions = () => {
    const options = document.querySelectorAll('.diet-item');
    window.restrictionsValue = {};

    options.forEach(option => {
      const key = option.getAttribute('data-name');
      const id = option.getAttribute('id')
      const value = option.classList.contains('yes') ? true : false;
      window.restrictionsValue[key];

      if (!window.restrictionsValue[key]) window.restrictionsValue[key] = {};
      window.restrictionsValue[key][id] = value;
    });

    document.getElementById('meal_plan_restrictions').value = JSON.stringify(window.restrictionsValue);
  }

  document.querySelectorAll('.diet-item').forEach(item => {
    item.addEventListener('click', ev => {
      const target = ev.target;

      target.classList = target.classList.contains('yes') ? 'diet-item no' : 'diet-item yes';
      setRestrictions();
    });
  });

  document.getElementById('next-step').addEventListener('click', ev => {
    const errors = [];
    document.querySelectorAll('.required').forEach(field => {
      if (!field.value) { errors.push(field.id); }
    });

    if (errors.length > 0) {
      document.querySelectorAll('.form-error').forEach(thing => { thing.innerHTML = 'Please fill out all fields'; });
    } else {
      document.querySelectorAll('.form-error').forEach(thing => { thing.innerHTML = ''; });
      $('#mp-step-1').addClass('hidden');
      $('#mp-step-2').removeClass('hidden');
      setRestrictions();
    }
    ev.preventDefault();
  });

  $('.editable-text').focus(ev => {
    $(ev.target).removeClass('flash');
  })

  $('.editable-text').blur(ev => {
    const data = {};
    const target = $(ev.target);
    const url = target.data('url');
    const type = target.data('type');
    const name = target.attr('name');
    const value = target.val().trim();

    data[type] = {}
    data[type][name] = value;

    $.ajax({
      method: "PUT",
      url,
      data: data,
      dataType: 'json',
      success: () => {
        target.addClass('flash');
        target.val(value);
      }
    });
    // $(ev.target).html(`<input type="text" value="${text}" class="w-full block" />`);
    // console.log($(ev.target).text());
  });

  if ($('.recipe-categories').length > 0) {
    $('.recipe-categories').selectize({
      onChange: value => {
        const newArray = [];
        let recipeId = 0;

        value.forEach(item => {
          const split = item.split(' ');
          recipeId = split[0];
          newArray.push(split[1])
        });
        $.ajax({
          method: "PUT",
          url: `/recipes/${recipeId}`,
          data: {
            recipe: {
              category_list: newArray.join(','),
            },
          },
          dataType: 'json',
        });
      },
    });
  }
};
