window.modalSetContent = (content) => {
  $('#modal-content').html(content);
}

window.showModal = () => {
  $('#modal').removeClass('hidden');

  setTimeout(() => {
    $('#modal').addClass('active');
  }, 50);
}

window.showErrorModal = (content) => {
  window.modalSetContent(
    '<div class="p-4 text-center"><h3 class="mb-4">Uh-oh! Problem detected.</h3>' +
    '<p class="text-lg">Looks like we had a problem. Please contact us at <a href="mailto:support@myketoplan.co">support@myketoplan.co</a>.</p>' +
    '</div>'
  );
  window.showModal();
}

window.hideModal = () => {
  $('#modal').removeClass('active');

  setTimeout(() => {
    $('#modal').addClass('hidden');
  }, 300);
}

window.modalStartLoading = () => {
  $('#modal-content').hide();
  $('#modal-loading').fadeIn();
  window.showModal();
};

window.modalStopLoading = () => {
  $('#modal-loading').fadeOut({
    done: () => { $('#modal-content').fadeIn() }
  });
};

window.showConfirmModal = (title, content, buttonText = 'Confirm', callback) => {
  window.modalSetContent(
    '<div class="p-4 text-center">' +
    `<h3 class="mb-4">${title}</h3>` +
    `<div class="confirm-content mb-4">${content}</div>` +
    '<div class="confirm-confirm">' +
    `<button id='confirm-button' class='bg-blue-600 text-white px-4 py-2 font-semibold hover:bg-blue-500'>${buttonText}</button>` +
    '</div>' +
    '</div>'
  );

  if (callback) {
    $('#confirm-button').click(callback);
  }
  window.showModal();
}
